$(document).foundation();

//@codekit-prepend "../bower_components/jquery_appear/jquery.appear.js";
//@codekit-prepend "../bower_components/svg-injector/dist/svg-injector.min.js";

(function () {
    var prev = 0;
    var $window = $(window);
    var nav = $('.top-bar-container');
    $window.on('scroll', function () {
        var scrollTop = $window.scrollTop();
        nav.toggleClass('hidden', scrollTop > prev && scrollTop > 20);
        nav.toggleClass('with-bg', scrollTop < prev && scrollTop > 80);
        prev = scrollTop;
    });

    var mySVGsToInject = document.querySelectorAll('img.inject-me');
    SVGInjector(mySVGsToInject);

    $.appear('.counter');
    $('.counter').each(function () {
            $(this).one('appear', function() {
                $(this).prop('Counter',0).animate({
                    Counter: $(this).text()
                }, {
                    duration: 3000,
                    easing: 'swing',
                    step: function (now) {
                        $(this).text(Math.ceil(now));
                    }
                });
            });
    });
})();